// eslint-disable-next-line lodash/import-scope
import type { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import queryString from 'query-string';
import { useMemo } from 'react';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { FrontEndCarrierReadByUser } from '@headway/api/models/FrontEndCarrierReadByUser';
import { ModalityRead } from '@headway/api/models/ModalityRead';
import { ProviderSearchIndexRecordRead } from '@headway/api/models/ProviderSearchIndexRecordRead';
import { ProviderSearchIndexResultsFilters } from '@headway/api/models/ProviderSearchIndexResultsFilters';
import { ProviderSearchRead } from '@headway/api/models/ProviderSearchRead';
import { ProviderStateSearchIndexRecordRead } from '@headway/api/models/ProviderStateSearchIndexRecordRead';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { TimeOfDayFilter } from '@headway/api/models/TimeOfDayFilter';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserSessionLocationPreference } from '@headway/api/models/UserSessionLocationPreference';
import { SearchIndexApi } from '@headway/api/resources/SearchIndexApi';
import { WeekdayFilter } from '@headway/shared/constants/search';
import { useQuery } from '@headway/shared/react-query';
import { isCarrierInHeadwayNetworkForState } from '@headway/shared/utils/carriers';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';
import {
  Filters,
  filtersToQueryParams,
  getDayOfWeekFromAvailabilityFilter,
  getProviderFunction,
} from '@headway/shared/utils/providerSearchFilters';

import {
  useKnownFrontEndCarrierIds,
  useSearchableFrontEndCarriersQuery,
} from './useSearchableFrontEndCarriersQuery';

const getCarrierIdIfInNetwork = (
  carrier: FrontEndCarrierRead | FrontEndCarrierReadByUser,
  options: {
    state?: UnitedStates;
    isBlueCard?: boolean;
  } = {}
): number | undefined => {
  const { state, isBlueCard } = options;
  if (!state) return;

  if (
    isCarrierInHeadwayNetworkForState(carrier, state) ||
    (isBlueCard && isFrontEndCarrierIdBcbs(carrier.id))
  ) {
    return carrier.id;
  }
};

const getTimeOfDayFromAvailabilityFilter = (
  availabilityFilters: string[]
): TimeOfDayFilter[] => {
  const timeOfDayFilterValues = Object.values(TimeOfDayFilter);
  return availabilityFilters.filter((f) =>
    timeOfDayFilterValues.includes(f as TimeOfDayFilter)
  ) as TimeOfDayFilter[];
};

type ProvidersSearchState = {
  providers:
    | ProviderSearchRead[]
    | ProviderSearchIndexRecordRead[]
    | ProviderStateSearchIndexRecordRead[];
  totalPages: number;
  totalProviderCount: number;
  queryId?: string;
  queryFilters?: ProviderSearchIndexResultsFilters;
  isFetching?: boolean;
  sessionId?: number;
};

interface Props {
  currentFilters: Filters;
  providersPerPage: number;
  specialties?: SpecialtyRead[];
  modalities?: ModalityRead[];
  currentSessionId?: number;
}

const areFiltersInitialized = (filters: Filters) => {
  /**
   * there's a race condition between loading the currentFilters from the query params
   * and the initial creation of currentFilters and there's no easy way to know if currentFilters
   * is actually considered "initialized," but since we don't want to run a search unless everything
   * is fully loaded, then we should make sure that the map bounds are set before we allow search to run.
   * Otherwise, as soon as the map bounds loads, we will retrigger search
   */
  return (
    filters.state &&
    filters.lon &&
    filters.lat &&
    filters.lowerLon &&
    filters.lowerLat &&
    filters.upperLon &&
    filters.upperLat
  );
};

export const useProvidersSearch = ({
  currentFilters,
  providersPerPage,
  specialties,
  currentSessionId,
}: Props): ProvidersSearchState => {
  const frontEndCarriersQuery = useSearchableFrontEndCarriersQuery();

  const carriersById:
    | Dictionary<FrontEndCarrierReadByUser | undefined>
    | Dictionary<FrontEndCarrierRead | undefined> = useMemo(
    () => keyBy(frontEndCarriersQuery.data, 'id'),
    [frontEndCarriersQuery.data]
  );
  const knownFrontEndCarrierIds = useKnownFrontEndCarrierIds();

  const queryResults = useQuery(
    ['providers-algolia', currentFilters, providersPerPage, specialties],
    () => {
      if (!areFiltersInitialized(currentFilters) || !specialties) {
        return null;
      }

      const carrierFromFilters = currentFilters.frontEndCarrierId
        ? carriersById[currentFilters.frontEndCarrierId]
        : undefined;

      // clean up fields that are deprecated
      delete currentFilters.sessionLocation;
      return SearchIndexApi.searchProvidersIndex({
        ethnicities: currentFilters.ethnicities,
        front_end_carriers: carrierFromFilters
          ? getCarrierIdIfInNetwork(carrierFromFilters, {
              state: currentFilters.state,
              isBlueCard: currentFilters.isBlueCard,
            })
          : undefined,
        known_front_end_carrier_ids: knownFrontEndCarrierIds,
        gender: currentFilters.genders,
        hits_per_page: providersPerPage,
        in_person: currentFilters.medium?.includes(
          UserSessionLocationPreference.IN_PERSON
        ),
        is_blue_card: currentFilters.isBlueCard,
        languages: currentFilters.languages,
        location_preference: currentFilters.sessionLocation,
        lower_lat: currentFilters.lowerLat,
        lower_lon: currentFilters.lowerLon,
        modalities: currentFilters.selectedModalities,
        near_me: !!currentFilters.address,
        page:
          currentFilters.currentPage < 1 ? 0 : currentFilters.currentPage - 1,
        patient_age: currentFilters.patientAge,
        patient_age_groups: currentFilters.forChild,
        phone_consult: currentFilters.phoneConsults,
        provider_function: getProviderFunction(currentFilters.typeOfCare),
        is_phypa_provider: currentFilters.isPhypaProvider,
        is_bti_provider: currentFilters.isBtiProvider,
        search_str: currentFilters.freeText,
        provider_age_group: currentFilters.providerAges,
        specialties: currentFilters.issues
          ?.map(
            (issue) =>
              specialties?.find((specialty) => specialty.key === issue)?.id
          )
          .filter(
            (specialtyId): specialtyId is number => specialtyId !== undefined
          ),
        // If we don't have a state we default to NY. User will be prompted to select their state.
        state: currentFilters.state,
        topics: currentFilters.topics,
        telehealth:
          currentFilters.medium?.includes(
            UserSessionLocationPreference.VIRTUAL
          ) ||
          currentFilters.medium?.includes(
            UserSessionLocationPreference.VIRTUAL_FOR_NOW
          ),
        upper_lat: currentFilters.upperLat,
        upper_lon: currentFilters.upperLon,
        day_of_week: getDayOfWeekFromAvailabilityFilter(
          currentFilters.availabilities ?? []
        ),
        time_of_day: getTimeOfDayFromAvailabilityFilter(
          currentFilters.availabilities ?? []
        ),
      });
    },
    { keepPreviousData: true, enabled: !frontEndCarriersQuery.isLoading }
  );

  return {
    providers: queryResults.data?.providers ?? [],
    totalPages: queryResults.data?.totalPages ?? 1,
    totalProviderCount: queryResults.data?.totalProviders ?? 0,
    queryId: queryResults.data?.queryId,
    sessionId: queryResults.data?.sessionId,
    queryFilters: queryResults.data?.queryFilters,
    // you need to add this `|| !algoriaQuery.data` to take into account the initial search
    // or else `isFetching` will be `true` -> `false` -> `true` and it then creates a UI glitch
    isFetching: queryResults.isFetching || !queryResults.data,
  };
};

export const getProvidersSearchPath = ({
  marketSlug,
  filters = {},
}: { marketSlug?: string; filters?: Partial<Filters> } = {}): string => {
  const basePath = marketSlug ? `/search/${marketSlug}` : '/search';

  const [params] = filtersToQueryParams(filters);

  return `${basePath}?${queryString.stringify(params)}`;
};
