import { Close, Menu } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  SlideProps,
} from '@mui/material';
import { ParsedUrlQuery } from 'querystring';
import React, { MouseEventHandler } from 'react';

import { HeadwayLogo } from '@headway/helix/HeadwayLogo';
import { theme } from '@headway/helix/theme';
import { trackEvent } from '@headway/shared/utils/analytics';
import { Button } from '@headway/ui/Button';
import { Chip } from '@headway/ui/Chip';
import { theme as legacyTheme } from '@headway/ui/theme';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { useRouter } from '../../hooks/useRouter';
import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import { HEADER_HEIGHT_VARIABLE } from '../../utils/cssVariables';
import { encodePath, REDIRECT_TO_QUERY_KEY } from '../../utils/redirect';
import { ButtonLink } from '../ButtonLink';
import { ImpersonatingUserConsumer } from '../ImpersonatingUserProvider';
import { Link } from '../Link';
import { DropdownGroup } from './DropdownGroup/DropdownGroup';
import { NavList } from './NavList';
import { NavListLink } from './NavListLink';
import { NavSection } from './NavSection';
import { NavTitle } from './NavTitle';

const Transition: React.FC<
  SlideProps & {
    children?: React.ReactElement<any, any>;
  }
> = (props, ref) => {
  return <Slide direction="down" timeout={150} ref={ref} {...props} />;
};

const SlideTransition = React.forwardRef(Transition as any);

export interface HeaderProps {
  animateOnScroll?: boolean;
  AuthStore: IAuthStore;
  banner?: React.ReactNode;
  ctaHref?: string;
  ctaLabel?: React.ReactNode;
  fullWidth?: boolean;
  onCtaClick?: MouseEventHandler<HTMLButtonElement>;
  UiStore: IUiStore;
}

function HeaderImpl(props: HeaderProps) {
  const [active, setActive] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const toggleDialog = () => {
    setDialogOpen((dialogOpen) => !dialogOpen);
  };

  React.useEffect(() => {
    function handleScroll() {
      if (window.scrollY <= 0) {
        setActive(false);
      } else if (!active) {
        setActive(true);
      }
    }

    function handleResize() {
      setDialogOpen(
        window.innerWidth < legacyTheme.breakpoints.medium && dialogOpen
      );
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [active, dialogOpen]);

  const CtaButton = props.ctaHref ? ButtonLink : Button;
  const { animateOnScroll = true, fullWidth = false } = props;

  const router = useRouter();

  return (
    <ImpersonatingUserConsumer>
      {(impersonatingUser) => (
        <React.Fragment>
          {dialogOpen ? null : props.banner ? props.banner : null}
          <header
            css={{
              position: dialogOpen ? 'fixed' : 'sticky',
              top: 0,
              zIndex: theme.layers.base + 2,
              width: '100%',
              background:
                impersonatingUser &&
                impersonatingUser.id !== props.AuthStore?.user?.id
                  ? legacyTheme.color.danger
                  : legacyTheme.color.white,
              borderBottom:
                active || dialogOpen || !animateOnScroll
                  ? `1px solid ${legacyTheme.color.border}`
                  : `1px solid transparent`,
              transition: 'border-bottom 200ms ease 0s',
            }}
          >
            <div
              css={{
                margin: '0 auto',
                display: 'flex',
                maxWidth: fullWidth ? undefined : 1200,
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: `${legacyTheme.space.xs} ${legacyTheme.space.xl}`,
                height: `var(${HEADER_HEIGHT_VARIABLE})`,
                [legacyTheme.media.small]: {
                  padding: `${legacyTheme.space.base} ${legacyTheme.space.xl}`,
                },
              }}
            >
              <Link
                href="/"
                title="Headway Home"
                className="flex w-[160px] flex-col justify-center"
              >
                <HeadwayLogo color="green" text="Headway" />
              </Link>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    display: 'none',
                    [legacyTheme.media.large]: {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <DropdownGroup aria-label="Main navigation" />

                  <Divider
                    orientation="vertical"
                    flexItem
                    css={{ margin: `0 ${legacyTheme.space.base}` }}
                    role="presentation"
                  />
                  {!!props.ctaLabel && (
                    <CtaButton
                      variant={active ? 'contained' : 'outlined'}
                      color={active ? 'primary' : 'gray'}
                      size="large"
                      css={{
                        borderColor: 'transparent',
                        ' .MuiButton-root': {
                          color: active
                            ? legacyTheme.color.white
                            : legacyTheme.color.darkGray,
                          transition: 'color 200ms ease 0s',
                        },
                        marginRight: legacyTheme.space.base,
                        transition: 'background-color 200ms ease 0s',
                      }}
                      onClick={props.onCtaClick}
                      href={props.ctaHref}
                    >
                      {props.ctaLabel}
                    </CtaButton>
                  )}
                </div>
                <div
                  css={{
                    display: 'none',
                    [legacyTheme.media.small]: {
                      display: 'flex',
                      gap: theme.spacing.x2,
                      alignItems: 'center',
                    },
                  }}
                >
                  <ButtonLink
                    variant="outlined"
                    size="large"
                    color="gray"
                    css={{
                      borderRadius: '8px',
                    }}
                    href={`${
                      process.env.NEXT_PUBLIC_CARE_URL
                    }/log-in?${REDIRECT_TO_QUERY_KEY}=${encodePath(
                      '/account'
                    )}`}
                    data-testid="login-header-button"
                  >
                    Log in
                  </ButtonLink>

                  <ButtonLink
                    variant="contained"
                    color="primary"
                    size="large"
                    href={{
                      pathname: '/for-providers',
                      hash: 'get-started',
                      query: getUtmParams(router.query),
                    }}
                    onClick={() => {
                      trackEvent({
                        name: 'Join as a Provider Button Clicked',
                      });
                    }}
                  >
                    Join as a provider
                  </ButtonLink>
                </div>

                <IconButton
                  aria-label={dialogOpen ? 'Close Menu' : 'Open Menu'}
                  css={{
                    marginLeft: legacyTheme.space.sm,
                    [legacyTheme.media.large]: { display: 'none' },
                  }}
                  onClick={toggleDialog}
                  size="large"
                >
                  {dialogOpen ? <Close /> : <Menu />}
                </IconButton>
              </div>
              <Dialog
                open={dialogOpen}
                TransitionComponent={SlideTransition as any}
                keepMounted={true}
                fullWidth={true}
                // Dialog is incompat with Helix modals
                disableEnforceFocus
                scroll="paper"
                onClose={toggleDialog}
                fullScreen={true}
                css={{
                  zIndex: `${theme.layers.base + 1} !important` as any,
                  ' .MuiPaper-root': {
                    backgroundColor: legacyTheme.color.primaryBackground,
                  },
                }}
              >
                <VisuallyHidden>
                  <DialogTitle>Menu Navigation</DialogTitle>
                </VisuallyHidden>
                <div
                  css={{
                    marginTop: legacyTheme.space.xl6,
                    padding: `${legacyTheme.space.sm} ${legacyTheme.space.xl}`,
                  }}
                >
                  <NavSection>
                    <NavTitle>Get Care</NavTitle>
                    <NavList>
                      <NavListLink href="/" onClick={toggleDialog}>
                        Find a provider
                      </NavListLink>
                      <NavListLink href="/#how-it-works">
                        How it works
                      </NavListLink>
                      <NavListLink href="/does-my-insurance-cover-therapy">
                        Does my insurance cover therapy?
                      </NavListLink>
                    </NavList>
                  </NavSection>
                  <NavSection>
                    <NavTitle>For Providers</NavTitle>
                    <NavList>
                      <NavListLink href="/for-providers">
                        Learn more
                      </NavListLink>
                      <NavListLink href="/for-providers#get-started">
                        Join Headway
                      </NavListLink>
                      <NavListLink
                        href={process.env.NEXT_PUBLIC_SIGMUND_URL || ''}
                      >
                        Provider Portal
                      </NavListLink>
                      <NavListLink href="/resources/all">
                        Provider Resource Center
                      </NavListLink>
                    </NavList>
                  </NavSection>
                  <NavSection>
                    <NavTitle>For Health Plans</NavTitle>
                    <NavList>
                      <NavListLink href="/for-health-plans">
                        Learn more
                      </NavListLink>
                      <NavListLink href="mailto:partnerships@headway.co">
                        Partner with us
                      </NavListLink>
                    </NavList>
                  </NavSection>
                  <NavSection>
                    <NavTitle>Company</NavTitle>
                    <NavList>
                      <NavListLink href="/about-us">About us</NavListLink>
                      <NavListLink href="/blog">Blog</NavListLink>
                      <NavListLink href="/press">Press</NavListLink>
                      <NavListLink href="/careers">
                        <span css={{ display: 'flex', alignItems: 'center' }}>
                          Careers
                          <Chip
                            label="We're hiring!"
                            size="small"
                            color="primary"
                            css={{
                              marginLeft: legacyTheme.space.xs,
                              color: legacyTheme.color.white,
                            }}
                          />
                        </span>
                      </NavListLink>
                    </NavList>
                  </NavSection>

                  <div
                    css={{
                      position: 'sticky',
                      background: legacyTheme.color.primaryBackground,
                      bottom: 0,
                      borderTop: `1px solid ${legacyTheme.color.border}`,
                      margin: '-0.75rem -1.5rem',
                      padding: theme.spacing.x3,
                      display: 'grid',
                      gridTemplateColumns: 'minmax(130px, auto) 1fr',
                      gridTemplateRows: 'auto',

                      gap: theme.spacing.x2,
                      alignItems: 'center',
                      [theme.__futureMedia.above('phone')]: {
                        gridTemplateColumns: '1fr 1fr',
                      },
                      [legacyTheme.media.small]: {
                        display: 'none',
                      },
                    }}
                  >
                    <ButtonLink
                      variant="outlined"
                      size="large"
                      color="gray"
                      href={`${
                        process.env.NEXT_PUBLIC_CARE_URL
                      }/log-in?${REDIRECT_TO_QUERY_KEY}=${encodePath(
                        '/account'
                      )}`}
                    >
                      Log in
                    </ButtonLink>
                    <ButtonLink
                      variant="contained"
                      color="primary"
                      size="large"
                      href={'/for-providers#get-started'}
                      onClick={() => {
                        trackEvent({
                          name: 'Join as a Provider Button Clicked',
                        });
                      }}
                    >
                      Join as a provider
                    </ButtonLink>
                  </div>
                </div>
              </Dialog>
            </div>
          </header>
        </React.Fragment>
      )}
    </ImpersonatingUserConsumer>
  );
}

function getUtmParams(query: ParsedUrlQuery) {
  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];

  let keep: ParsedUrlQuery = {};

  for (const key of utmParams) {
    if (query[key]) {
      keep[key] = query[key];
    }
  }

  return keep;
}

export const Header = withStores(HeaderImpl);
