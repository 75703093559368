/**
 * See vite.config.ts for the list of defined remix routes.
 */
export const REMIX_ROUTES = [
  '/careers',
  '/m/:slug',
  // '/does-my-insurance-cover-therapy',
  // '/blog',
  // '/blog/:slug',
  // '/legal/*',
];
